import styled from 'styled-components'
import { motion } from 'framer-motion'


export const Form = styled(motion.form)`
  width: 100%;
  max-width: 450px;
  height: 550px;
  margin: 50px auto;
  padding: 0 36px;
  
  & button {
    margin-top: 10px;
  }
`

export const FormTitle = styled.h2`
  font-family: 'Play', sans-serif;
  font-size: 30px;
  color: whitesmoke;
  text-align: center;
`;