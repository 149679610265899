import * as React from 'react'
import ReactPlayer from 'react-player/lazy'
import { Helmet } from 'react-helmet'
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import LogoSrc from '../images/logo.png'
import Apply from '../components/Apply'
import useHomeQuery from '../homeGraphql'
import ApplyForm from '../components/ApplyForm'
import {
  Main,
  GlobalStyle,
  Video,
  AboutText,
  Header,
  VideoContainer,
  Nav,
  Logo,
  AwardImageList,
  AwardImage,
  AwardImageContainer,
  FormContainer,
  CarouselContainer,
  CarouselControls,
} from '../styles'
import CarouselSlide from '../components/CarouselSlide'

const IndexPage = () => {
  const [applyOpen, setApplyOpen] = React.useState(false)
  const [isCarouselPlaying, setIsCarouselPlaying] = React.useState(true)
  const page = useHomeQuery()

  const {
    videoLink,
    videoImagePlaceholder,
    aboutText: text,
    testimonials,
    testimonialVideoLink,
    files: awards,
  } = page

  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>ODYSSEY</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Nav>
        <Logo src={LogoSrc} alt="logo" />
        <Apply setApplyOpen={setApplyOpen} isOpen={applyOpen} />
      </Nav>
      <Main>
        <VideoContainer>
          <Video>
            <ReactPlayer
              className="react-player"
              height="100%"
              width="100%"
              url={videoLink}
              controls
              config={{
                youtube: {
                  playerVars: { showinfo: 1, autohide: 1, autoplay: 1 },
                },
              }}
              light={videoImagePlaceholder.file.url}
            />
          </Video>
        </VideoContainer>
        <AboutText>{text.aboutText}</AboutText>

        <Header>
          OUR <span>AWARDS</span>
        </Header>

        <AwardImageList>
          {awards.map((a, i) => (
            <AwardImageContainer key={a}>
              <AwardImage src={a} />
            </AwardImageContainer>
          ))}
        </AwardImageList>

        {testimonialVideoLink && (
          <VideoContainer>
            <Video>
              <ReactPlayer
                className="react-player"
                height="100%"
                width="100%"
                url={testimonialVideoLink}
                controls
              />
            </Video>
          </VideoContainer>
        )}
        {/*        {testimonials && (
          <TestimonialGrid>
            {testimonials.map(({ blurb, image, name, id }) => (
              <Testimonial key={id}>
                <TestimonialImg src={`https:${image.file.url}`} />
                <TestimonialP>{blurb}</TestimonialP>
                <TestimonialBy>- {name}</TestimonialBy>
              </Testimonial>
            ))}
          </TestimonialGrid>
        )}*/}

        {!applyOpen && <Apply setApplyOpen={setApplyOpen} />}

        <FormContainer isOpen={applyOpen} id="apply-form">
          <ApplyForm />
        </FormContainer>

        {testimonials?.[0]?.videoLink && (
          <VideoContainer>
            <Video>
              <ReactPlayer
                className="react-player"
                height="100%"
                width="100%"
                url={testimonials?.[0]?.videoLink}
                controls
                config={{
                  youtube: {
                    playerVars: { showinfo: 1, autohide: 1 },
                  },
                }}
                light={testimonials?.[0]?.image?.file?.url}
              />
            </Video>
          </VideoContainer>
        )}
        {testimonials && (
          <CarouselContainer>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={56.25}
              totalSlides={testimonials.length - 1}
              visibleSlides={1}
              infinite
              isPlaying={isCarouselPlaying}
            >
              <Slider>
                {testimonials.slice(1).map(({ videoLink, image, id }, i) => (
                  <CarouselSlide
                    id={id}
                    index={i}
                    setIsCarouselPlaying={setIsCarouselPlaying}
                    videoLink={videoLink}
                    image={image}
                  />
                ))}
              </Slider>
              <CarouselControls>
                <DotGroup className="dots" />
                <div className="btns">
                  <ButtonBack className="btn back">Back</ButtonBack>
                  <ButtonNext className="btn next">Next</ButtonNext>
                </div>
              </CarouselControls>
            </CarouselProvider>
          </CarouselContainer>
        )}
      </Main>
    </React.Fragment>
  )
}

export default IndexPage
