import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import * as React from 'react'
import { Form, FormTitle } from './styles'

const ApplyForm = () => {
  const darkTheme = createTheme({
    palette: {
      type: 'dark',
    },
  })

  const [radio, setRadio] = React.useState()

  return (
    <ThemeProvider theme={darkTheme}>
      <FormTitle>APPLY</FormTitle>
      <Form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="application"
        autoComplete="off"
        action="/apply-success"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="application" />
        <TextField
          id="name"
          name="name"
          label="Name"
          margin="normal"
          required
          fullWidth
        />
        <TextField
          id="number"
          name="number"
          label="Number"
          margin="normal"
          required
          fullWidth
        />
        <TextField
          id="zip"
          name="zip"
          label="Zipcode"
          margin="normal"
          required
          fullWidth
        />
        <FormControl component="fieldset" margin="normal" required fullWidth>
          <FormLabel component="legend">Years of Experience</FormLabel>
          <RadioGroup name="experience">
            <FormControlLabel
              value="Junior"
              control={<Radio color="default" />}
              label="0-1"
            />
            <FormControlLabel
              value="Mid"
              control={<Radio color="default" />}
              label="1-2"
            />
            <FormControlLabel
              value="Senior"
              control={<Radio color="default" />}
              label="2+"
            />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" margin="normal" required fullWidth>
          <FormLabel component="legend">How did you hear about us?</FormLabel>
          <RadioGroup
            name="heard"
            value={radio}
            onChange={(event) => {
              setRadio(event.target.value)
            }}
          >
            <FormControlLabel
              value="Social"
              control={<Radio color="default" />}
              label="Social Media"
            />
            <FormControlLabel
              value="Job Description"
              control={<Radio color="default" />}
              label="Job Description"
            />
            <FormControlLabel
              value="Referred"
              control={<Radio color="default" />}
              label="Referred By Someone"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          id="referred"
          name="referred"
          label="Who were you referred by?"
          margin="normal"
          fullWidth
          style={{ opacity: radio === 'Referred' ? 1 : 0 }}
          required={radio === 'Referred'}
        />

        <Button variant="contained" type="submit" margin="normal">
          Submit
        </Button>
      </Form>
    </ThemeProvider>
  )
}
export default ApplyForm
