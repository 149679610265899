import * as React from 'react'
import { ApplyLink } from './styles'

const Apply = ({ setApplyOpen, isOpen }) => (
  <ApplyLink
    onClick={() => {
      if (!isOpen) {
        setApplyOpen(true)
      }
      setTimeout(() => {
        const elem = document.getElementById('apply-form')
        window.scroll({
          top: elem.offsetTop,
          left: 0,
          behavior: 'smooth',
        })
      }, 250)
    }}
    layout
  >
    APPLY
  </ApplyLink>
)

export default Apply
