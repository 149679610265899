import { graphql, useStaticQuery } from 'gatsby'

const useHomeQuery = () => {
  const { contentfulLandingPage: page, allFile: files } = useStaticQuery(graphql`
    {
      contentfulLandingPage {
        id
        aboutText {
          id
          aboutText
        }
        companyName
        logo {
          id
          title
          file {
            url
          }
        }
        videoLink
        videoImagePlaceholder {
          id
          title
          file {
            url
          }
        }
#        add this back in if they are ever wanted
        testimonials {
          name
          blurb
          id
          videoLink
          image {
            title
            file {
              url
            }
          }
        }
        testimonialVideoLink
      }
      allFile(filter: { extension: { eq: "png" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  return { ...page, files: files.edges.map(f => f.node.publicURL) }
}
export default useHomeQuery
