import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ApplyLink = styled(motion.button)`
  display: inline-block;
  text-decoration: none;
  background-color: #f3cd59;
  color: white;
  cursor: pointer;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  height: 50px;
  padding: 0 33px;
  border-radius: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 250px;
  margin: 50px auto;
  border: none;
`
