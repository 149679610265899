import React from 'react'
import { CarouselContext, Slide } from 'pure-react-carousel'
import { Video, VideoContainerFull } from '../../styles'
import ReactPlayer from 'react-player/lazy'

const CarouselSlide = ({ setIsCarouselPlaying, index, id, videoLink, image }) => {
  const carouselContext = React.useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = React.useState(
    carouselContext.state.currentSlide
  )
  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <Slide index={index} key={id}>
      <VideoContainerFull>
        <Video>
          <ReactPlayer
            className="react-player"
            height="100%"
            width="100%"
            url={videoLink}
            playing={currentSlide === index && isPlaying}
            onPlay={() => {
              setIsCarouselPlaying(false);
              setIsPlaying(true)
            }}
            onPause={() => {
              setIsCarouselPlaying(true);
              setIsPlaying(false)
            }}
            controls
            config={{
              youtube: {
                playerVars: { showinfo: 1, autohide: 1, autoplay: 1 },
              },
            }}
            light={image.file.url}
          />
        </Video>
      </VideoContainerFull>
    </Slide>
  )
}

export default CarouselSlide
